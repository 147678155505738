import * as React from "react";
import {Component, ReactNode} from "react";
import {Model} from "../../model/Model";

interface Properties {
    model: Model;
}

export class NavigationBar extends Component<Properties> {

    constructor(props) {
        super(props);
        this.onModel = this.onModel.bind(this);

        this.props.model.dispatcher.addListener(this.onModel);
    }

    private onModel() {
    }

    public render(): ReactNode {
        return (
            <div className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="#"><strong>White Cards</strong></a>

                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <a className="nav-link" data-name="students" href="#"
                        >Students</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-name="settings" href="#settings"
                        >Settings</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-name="statuses" href="#statuses"
                        >Statuses</a>
                    </li>
                </ul>
                <ul className="nav navbar-nav navbar-right">
                    Welcome {this.props.model.user.first_name} {this.props.model.user.last_name}
                </ul>
            </div>
        );
    }

    private onClick(e) {
        e.preventDefault();
        // let name = e.target.getAttribute("data-name");
        // console.log(name);
        // this.props.model.page = name;
        this.props.model.dispatcher.dispatch();
    }
}