import * as React from "react";
import {Gate} from "../../Gate";
import {Model} from "../../model/Model";

interface Properties {
    model: Model;
    gate: Gate;
}

interface State {
    statuses: any[]
}


export class Statuses extends React.Component<Properties, State> {


    constructor(props) {
        super(props);
        this.onGate = this.onGate.bind(this);

        this.state = {statuses: []};
    }

    private onGate(data) {

        if (data.statuses) {
            this.setState({statuses: data.statuses});
        }
    }

    public async componentDidMount() {

        this.props.gate.addListener(this.onGate);
        let r = await this.props.gate.getStatuses();
    }

    public componentWillUnmount() {
        this.props.gate.removeListener(this.onGate);
    }

    private onChange(e) {
        let id = parseInt(e.currentTarget.getAttribute("data-id"));
        console.log(id);
        let statuses = this.state.statuses;
        for (let s of statuses) {
            if (s.id === id) {
                s.enabled = !s.enabled;
            }
        }

        this.setState({statuses: statuses});
    }

    public render() {

        let rows = this.state.statuses.map((item, index) => {
            return (
                <li key={item.id} style={{listStyle: "none"}}>
                    <input
                        name="isGoing"
                        type="checkbox"
                        data-id={item.id}
                        checked={item.enabled}
                        onChange={this.onChange.bind(this)}/>
                    <span>&nbsp;{item.name}</span>
                </li>
            )
        });


        return (
            <div>
                <h3>Select student statuses to display in search result</h3>
                <div className="row">
                    <div className="col-md-6">
                        <ul>
                            {rows}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-1">
                        <input
                            className="btn btn-primary form-control mt-auto button"
                            type="submit"
                            value="Save"
                            onClick={this.onSave.bind(this)}/>
                    </div>
                </div>
            </div>
        );
    }

    private onSave() {
        console.log("Save");
        this.props.gate.saveStatuses(this.state.statuses);
    }

    private clear() {

    }
}