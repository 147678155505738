import {EventDispatcher} from "./model/EventDispatcher";
import {Config} from "./config/Config";
import {AxiosInstance} from "axios";

const axiosModule = require("axios");

export class Gate extends EventDispatcher {

    private axios: AxiosInstance;

    constructor(private config: Config) {
        super();
        //@ts-ignore
        this.axios = axiosModule.create();
        this.axios.defaults.headers.common["client_id"] = this.config.CLIENT_ID;
    }

    public setToken(token: string) {
        this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    public async login(email, password): Promise<any> {
        return await this.post("v1/api/login",
            {
                client_id: this.config.CLIENT_ID,
                email,
                password
            }
        );
    }

    public async refreshToken(): Promise<any> {
        return await this.post("v1/api/refresh-token", {});
    }

    public async getDashboard(): Promise<any> {
        return await this.post("v1/api/get-dashboard", {});
    }

    public async getCampuses(): Promise<any> {
        return await this.post("v1/api/get-campuses", {});
    }

    public async getMajors(campusId: number): Promise<any> {
        return await this.post("v1/api/get-majors", {campusId});
    }

    public async getUsers(text: string, locations: number[], majors: number[], onTrack: "All" | "OnTrack" | "OffTrack"): Promise<any> {
        return await this.post("v1/api/get-users", {text: text, locations: locations, majors, onTrack});
    }

    public async getUsersV2(data): Promise<any> {
        return await this.post("v1/api/get-users/v2", data);
    }


    public async getWhitecard(user): Promise<any> {
        return await this.post("v1/api/get-whitecard", {user});
    }

    public async saveSignature(signature: string): Promise<any> {
        return await this.post("v1/api/save-signature", {signature});
    }

    public async getSignature(): Promise<any> {
        return await this.post("v1/api/get-signature", {});
    }

    public async getStatuses(): Promise<any> {
        return await this.post("v1/api/get-statuses", {});
    }

    public async saveStatuses(data): Promise<any> {
        return await this.post("v1/api/save-statuses", data);
    }

    private async post(relativeUrl, data): Promise<any> {

        let url = `${this.config.API_DOMAIN}/${relativeUrl}`;
        let response = await this.axios.post(url, data);
        this.dispatch(response.data);

        return response.data;
    }
}

