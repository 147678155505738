import * as React from "react";
import {Component} from "react";
import {Gate} from "../../Gate";
import {LoginFormProperties} from "./LoginForm";
import {Model} from "../../model/Model";

interface Properties {
    targetComponent;
    model: Model;
    gate: Gate;
}

interface State {
    isLoading: boolean;
    errors: any
    globalError?: string;
}

export class LoginFormWrapper extends Component<Properties, State> {

    constructor(props) {
        super(props);

        this.state = {isLoading: false, errors: {}};
    }

    public render() {
        return React.createElement<LoginFormProperties>(this.props.targetComponent,
            {
                isLoading: this.state.isLoading,
                onChange: this.onChange.bind(this),
                errors: this.state.errors,
                globalError: this.state.globalError
            });
    }

    private isString(x) {
        return Object.prototype.toString.call(x) === "[object String]";
    }

    private async onChange(email, password) {

        this.setState({globalError: undefined, errors: {}, isLoading: true});

        try {
            let response = await this.props.gate.login(email, password);

            this.setState({isLoading: false});
            //@ts-ignore
            if (response.authorized) {
                this.props.model.authorized = true;
                this.props.model.currentPage = "staffDashboard";
                this.props.model.token = response.token;
                this.props.model.user = response.user;
                this.props.model.dispatcher.dispatch();
            } else {
                if (response.errors) {
                    if (this.isString(response.errors)) {
                        this.setState({globalError: response.errors});
                    } else {
                        this.setState({errors: response.errors});
                    }

                } else {
                    this.setState({globalError: "Wrong email or password"});
                }

            }
        } catch (error) {

            console.log(error);
            console.log("Global error");
            this.setState({globalError: "Can not connect to server"});
        }
    }

}