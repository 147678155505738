import * as React from "react";
import {Component} from "react";

import {Row} from "./Row/Row";
import {Model} from "../../../model/Model";
import {Gate} from "../../../Gate";

interface Properties {
    model: Model;
    gate: Gate;
}

interface State {
    users: any[]
}

export class StudentsList extends Component<Properties, State> {

    constructor(props) {
        super(props);
        this.state = {users: []};
    }

    public componentDidMount() {
        this.onModel = this.onModel.bind(this);
        this.props.model.dispatcher.addListener(this.onModel);
        this.onModel();
    }

    public componentWillUnmount() {
        this.props.model.dispatcher.removeListener(this.onModel);
    }


    private onModel() {
        if (this.props.model.page.staffDashboard) {
            this.setState({users: this.props.model.page.staffDashboard.users});
        }
    }

    private async onStudentSelected(u) {
        let data = await this.props.gate.getWhitecard(u);
        this.props.model.selectedWhitecard = data.whitecard;
        this.props.model.dispatcher.dispatch();
    }

    public render() {

        let rows = this.state.users.map((user, index) => {
            return <Row user={user} key={index} config={this.props.model.config} onChange={this.onStudentSelected.bind(this)}/>;
        });

        return (
            <div className="">
                <div style={{paddingTop: "20px"}}></div>
                <div><strong>Total: {this.state.users.length}</strong></div>
                <div style={{paddingTop: "20px"}}></div>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">#</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Program</th>
                        <th scope="col">Email</th>
                        <th scope="col">Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}