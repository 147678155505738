import * as React from "react";
import {Component} from "react";
import {Filter, FilterState} from "./Filter";
import {Gate} from "../../../Gate";
import {Model} from "../../../model/Model";
import {getClientId} from "../../../index";

interface Properties {
    model: Model;
    gate: Gate;
}

interface State {
    campuses: any[];
    programs: any[];
    disabled: boolean;
}

export class FilterWrapper extends Component<Properties, State> {

    constructor(props) {
        super(props);

        this.state = {campuses: [], programs: [], disabled: false};
        this.onGate = this.onGate.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onCampusChange = this.onCampusChange.bind(this);
    }

    private onGate(data) {
        if (data.campuses) {
            let arr = data.campuses.map((item) => {
                return {name: item.name, value: item.id};
            });

            this.setState({campuses: arr});
        }

        if (data.majors) {
            let arr = data.majors.map((item) => {
                return {name: item.name, value: item.id};
            });

            this.setState({programs: arr});
        }
    }

    public async componentDidMount() {
        this.props.gate.addListener(this.onGate);

        this.setState({disabled: true});
        let campuses = await this.props.gate.getCampuses();
        this.onGate(campuses);
        let majors = await this.props.gate.getMajors(0);
        this.onGate(majors);
        this.setState({disabled: false});
    }

    public async componentWillUnmount() {
        this.props.gate.removeListener(this.onGate);
    }

    public render() {
        return <Filter campuses={this.state.campuses}
                       programs={this.state.programs}
                       onChange={this.onFilterChange}
                       disabled={this.state.disabled}
                       onCampusChanged={this.onCampusChange}
                       gate={this.props.gate}
        />;
    }

    private async onCampusChange(campusId: number) {
        console.log(campusId);
        this.setState({disabled: true});
        let majors = await this.props.gate.getMajors(campusId);
        this.setState({disabled: false});
        console.log(majors);
    }

    private async onFilterChange(filterState: FilterState) {

        this.setState({disabled: true});

        if (getClientId() === "tricoci") {
            await this.getUsersV2(filterState);
        } else {
            await this.getUsersV1(filterState);
        }

        this.setState({disabled: false});
    }

    private async getUsersV2(filterState: FilterState) {
        const sort = {
            orderBy: "first_name",
            order: "DESC"
        };

        const filter = {
            text: filterState.text,
            locations: [filterState.campusId],
            majors: [filterState.programId],
            onTrack: filterState.onTrack
        };

        const pagination = {
            page: 1,
            pageSize: 10
        };

        await this.props.gate.getUsersV2({sort, filter, pagination});
    }

    private async getUsersV1(filterState: FilterState) {
        await this.props.gate.getUsers(filterState.text, [filterState.campusId], [filterState.programId], filterState.onTrack);
    }
}