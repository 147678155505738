import * as React from "react";
import "./App.css";
import {Model, Pages} from "../../model/Model";
import {Gate} from "../../Gate";
import {StaffDashboard} from "../StaffDashboard/StaffDashboard";
import {MainPage} from "../MainPage";
import {LoginPage} from "../auth/LoginPage";

interface Properties {
    model: Model;
    gate: Gate;
}

interface State {
    currentPage: Pages;
    globalErrors: string[];
}

export class App extends React.Component<Properties, State> {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: this.props.model.currentPage,
            globalErrors: this.props.model.globalErrors
        };

        this.onModel = this.onModel.bind(this);

        this.props.model.dispatcher.addListener(this.onModel);
    }

    public async componentDidMount() {


    }

    private onModel() {

        let m = this.props.model;
        this.setState({currentPage: m.currentPage, globalErrors: m.globalErrors});
    }

    render() {
        const cover = (
            <div>
                <div className="loader"></div>
                <div className="cover">
                </div>
            </div>

        );

        const errors = (
            <div>
                {this.state.globalErrors.map((item: string, index) => {
                    return (
                        <div className="alert alert-danger" key={index}>
                            {item}
                        </div>
                    );
                })}
            </div>
        );
        return (
            <div className="container">
                {this.props.model.globalErrors.length > 0 && errors}
                {this.state.currentPage == "loading" && cover}
                {this.state.currentPage == "staffDashboard" &&
                <StaffDashboard model={this.props.model} gate={this.props.gate}/>}
                {this.state.currentPage == "login" &&
                <LoginPage model={this.props.model} gate={this.props.gate}/>}

                <div className=""></div>
                <MainPage/>
            </div>

        );
    }
}
