import {Component} from "react";
import * as React from "react";
import "./Row.css";
import {Config} from "../../../../config/Config";


interface Properties {
    user: any;
    onChange: Function;
    config: Config;
}

export class Row extends Component<Properties> {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }


    public render() {
        let u = this.props.user;
        let date = "";
        if (u.created_at) {
            date = new Date(u.created_at).toLocaleDateString("en-US",
                {year: "numeric", month: "long", day: "numeric"}
            );
        }

        let group = "";
        if (u.groups && u.groups.length == 1) {
            group = u.groups[0].name;
        }

        let baseCardURL = `http://${this.props.config.WC_DOMAIN}`;
        let url = `${baseCardURL}?jwt=${u.token}`;
        let studentUrl = `${baseCardURL}?jwt=${u.studentToken}`;

        let baseCardURL2 = `http://51.15.77.240:8888`;
        let url2 = `${baseCardURL2}?jwt=${u.token}`;
        let studentUrl2 = `${baseCardURL2}?jwt=${u.studentToken}`;
        return (
            <tr className="hoverDiv" onClick={this.onClick}>
                <td>
                    <a href={url} target="_blank">White Card</a> (<a href={studentUrl} target="_blank">as student</a>)
                    <a href={url2} target="_blank">TMP</a> (<a href={studentUrl2} target="_blank">TMP as student</a>)
                </td>
                <th scope="row">{u.college_id}</th>
                <td>{u.first_name}</td>
                <td>{u.last_name}</td>
                <td>{u.major_name}</td>
                <td>{u.email}</td>
                <td>{date}</td>
                {/*<td><a href={url} target="_blank">Check</a></td>*/}
            </tr>
        );
    }

    private onClick() {
        //this.props.onChange(this.props.user);
    }
}