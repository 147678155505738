import {Model} from "./model/Model";
import {Gate} from "./Gate";

export class TokenListener {

    private token: string;

    constructor(private model: Model, private gate: Gate) {
        this.onModel = this.onModel.bind(this);
        model.dispatcher.addListener(this.onModel);
    }

    private onModel() {
        if (this.token != this.model.token) {
            if (this.model.token) {
                window.localStorage.setItem("token", this.model.token);
                this.token = this.model.token;

                this.gate.setToken(this.model.token);
            }

            console.log("Token saved");
        }
    }
}