import * as React from "react";
import {Gate} from "../../Gate";
import {Model} from "../../model/Model";

declare var SignaturePad;

interface Properties {
    model: Model;
    gate: Gate;
}


export class Settings extends React.Component<Properties> {

    private canvas;
    private signaturePad;

    constructor(props) {
        super(props);
        this.onGate = this.onGate.bind(this);
    }

    private onGate(data) {
        if (data.signature) {
            this.resizeCanvas();
            //this.signaturePad.fromDataURL(r.signature);

            let element = document.getElementById("img");
            if (element) {
                element.setAttribute("src", data.signature);
            }
        }
    }

    public async componentDidMount() {


        this.canvas = document.querySelector("canvas");

        // window.onresize = this.resizeCanvas;
        // this.resizeCanvas();
        this.signaturePad = new SignaturePad(this.canvas);

        this.props.gate.addListener(this.onGate);
        let r = await this.props.gate.getSignature();

    }

    public componentWillUnmount() {
        this.props.gate.removeListener(this.onGate);
    }

    public render() {
        return (
            <div>
                <h3>Create or edit your signature</h3>
                <div className="row">
                    <div className="col-md-6">
                        <h4>Make new Signature</h4>
                        <canvas id="canvas" width="400" height="200" style={{border: "solid 1px lightgrey"}}></canvas>
                        <div>
                            <button
                                className="btn btn-primary"
                                onClick={this.save.bind(this)}>Save
                            </button>
                            <button
                                className="btn btn-danger"
                                style={{marginLeft: "20px"}}
                                onClick={this.clear.bind(this)}>Clear
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h4>Current Signature</h4>
                        <img src="" alt="" id="img" width="400" height="200" style={{border: "solid 1px lightgrey"}}/>
                    </div>
                </div>
            </div>
        );
    }

    private save() {
        if (this.signaturePad.isEmpty()) {
            return alert("Please provide a signature first.");
        }
        let data = this.signaturePad.toDataURL("image/png");
        this.props.model.tmp = data;
        console.log(JSON.stringify(data));
        console.log("save");
        this.props.gate.saveSignature(data);
    }

    private clear() {
        console.log("clear");
        this.signaturePad.clear();
    }

    private resizeCanvas() {
        // When zoomed out to less than 100%, for some very strange reason,
        // some browsers report devicePixelRatio as less than 1
        // and only part of the canvas is cleared then.

        let canvas = this.canvas;
        let ratio = Math.max(window.devicePixelRatio || 1, 1);
        console.log("Ratio=" + ratio);
        // canvas.width = canvas.offsetWidth * ratio;
        // canvas.height = canvas.offsetHeight * ratio;
        //canvas.getContext("2d").scale(ratio, ratio);
        this.signaturePad.clear();
    }
}