import * as React from "react";
import * as ReactDOM from "react-dom";
import {App} from "./components/App/App";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import {Model} from "./model/Model";
import {Gate} from "./Gate";
import {EventDispatcher} from "./model/EventDispatcher";
import {GateListener} from "./GateListener";
import {TokenListener} from "./TokenListener";
import {Config} from "./config/Config";

console.log(process.env);

if (!process.env.REACT_APP_API_DOMAIN) {
    alert("REACT_APP_API_DOMAIN not set");
}

const CLIENT_ID = getClientId();
if (!CLIENT_ID) {
    alert("can not detect client_id by domain");
    throw new Error("can not detect client_id by domain");
}

let config: Config = {
    API_DOMAIN: process.env.REACT_APP_API_DOMAIN as string,
    CLIENT_ID: CLIENT_ID,
    WC_DOMAIN: getWhitecardDomain()
};

let model: Model = {
    authorized: false,
    urls: [],
    currentPage: "loading",
    page: {
        loading: {},
        staffDashboard: {
            filter: {
                college: 0
            },
            users: []
        },
        studentDashboard: {}
    },
    dispatcher: new EventDispatcher(),
    //token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic3RhZmYiLCJpYXQiOjE1Mjk2NTQ2MTB9.yeHM7rgXs48WG4XVWXP-_aPvqZoiYdkK55lFva37dAc",
    token: "",
    globalErrors: [],
    selectedUser: null,
    selectedWhitecard: null,
    config: config
};

console.log(window.location.hostname);

window["model"] = model;


let gate = new Gate(config);

if (model.token == "") {
    model.currentPage = "login";
}


gate.setToken(model.token);

gate.refreshToken().then((r) => {

}).catch(() => {
    model.globalErrors = ["Can not connect to server"];
    model.dispatcher.dispatch();
});


ReactDOM.render(
    <App model={model} gate={gate}/>,
    document.getElementById("root") as HTMLElement
);

new GateListener(gate, model);
new TokenListener(model, gate);

registerServiceWorker();

window.addEventListener("hashchange", onHash);
onHash();

function onHash() {
    console.log("hashchange !!!!!");
    console.log(location.hash);

    let urls = location.hash.split("/");
    if (urls[0]) {
        urls[0] = urls[0].replace("#", "");
    }
    console.log(urls);
    model.urls = urls;
    model.dispatcher.dispatch();
}


export function getClientId() {
    let domain = window.location.hostname;
    console.log(`DOMAIN=${domain}`);
    if (domain.indexOf("admin.bellus.klassdocs.") === 0) {
        return "bellus";
    }

    if (domain.indexOf("admin.whitecards.klassdocs.") === 0) {
        return "tricoci";
    }

    if (domain.indexOf("admin.anu.klassdocs.") === 0) {
        return "anu";
    }

    if (domain.indexOf("admin.issaco.klassdocs.") === 0) {
        return "issaco";
    }

    return null;
}

function getWhitecardDomain() {
    let domain = window.location.hostname;
    domain = domain.replace("admin.", "");
    return domain;
}