import * as React from "react";
import {Component} from "react";
import {FilterWrapper} from "./Filter/FilterWrapper";
import {Gate} from "../../Gate";
import {Model} from "../../model/Model";
import {StudentsList} from "./StudentsList/StudentsList";
import {NavigationBar} from "./NavigationBar";
import {Settings} from "./Settings";
import {Statuses} from "./Statuses";

interface Properties {
    model: Model;
    gate: Gate;
}

interface State {
    urls: string[];
    selectedWhitecard;
}

export class StaffDashboard extends Component<Properties, State> {

    constructor(props) {
        super(props);

        this.state = {selectedWhitecard: 0, urls: []};
        this.onModel = this.onModel.bind(this);
        this.props.model.dispatcher.addListener(this.onModel);
    }

    private onModel() {
        this.setState({selectedWhitecard: this.props.model.selectedWhitecard, urls: this.props.model.urls});
    }

    public render() {

        let content;

        if (this.state.urls[0] == "settings") {
            content = <Settings model={this.props.model} gate={this.props.gate}/>;
        } else if (this.state.urls[0] == "statuses") {
            content = <Statuses model={this.props.model} gate={this.props.gate}/>
        }
        else {
            content = (
                <div>
                    {this.state.selectedWhitecard == null && <div>
                        <FilterWrapper model={this.props.model} gate={this.props.gate}/>
                        <StudentsList model={this.props.model} gate={this.props.gate}/>
                    </div>}
                </div>
            );
        }

        return (
            <div>
                <div style={{paddingTop: "20px"}}></div>
                <NavigationBar model={this.props.model}/>
                <div style={{paddingTop: "20px"}}></div>
                {content}
            </div>
        );
    }
}