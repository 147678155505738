import * as React from "react";
import {Component} from "react";
import {Gate} from "../../Gate";
import {LoginFormWrapper} from "./LoginFormWrapper";
import {LoginForm} from "./LoginForm";
import {Model} from "../../model/Model";

interface Properties {
    model: Model;
    gate: Gate;
}

export class LoginPage extends Component<Properties> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className="row" style={{marginTop: "10%"}}>
                <div className="col-md-4 offset-md-4">
                    <LoginFormWrapper
                        model={this.props.model}
                        gate={this.props.gate}
                        targetComponent={LoginForm}
                    >
                    </LoginFormWrapper>
                </div>
            </div>

        );
    }
}