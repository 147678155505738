import {Model} from "./model/Model";
import {Gate} from "./Gate";

export class GateListener {

    constructor(private gate: Gate, private model: Model) {
        this.onGate = this.onGate.bind(this);
        this.gate.addListener(this.onGate);
    }

    private onGate(data) {
        console.log(data);
        if (data.users) {
            this.model.page.staffDashboard.users = data.users;
            this.model.dispatcher.dispatch();
        }

        if (data.whitecard) {
            this.model.selectedWhitecard = data.whitecard;
            this.model.dispatcher.dispatch();
        }

        if (data.token) {
            this.model.token = data.token;
            this.gate.setToken(data.token);
        }
        if (data.authorized) {
            // this.model.currentPage = "staffDashboard";
            // this.model.dispatcher.dispatch();
        }
    }
}