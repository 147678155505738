import {Component, SyntheticEvent} from "react";
import * as React from "react";
import "./Filter.css";
import * as classNames from "classnames";
import {Gate} from "../../../Gate";

interface Properties {
    campuses: any[];
    onChange: Function;
    disabled: boolean;
    onCampusChanged?: Function;
    programs: any[];
    gate: Gate;
}

export interface FilterState {
    text: string;
    campusId: number;
    programId: number;
    onTrack: "All" | "OnTrack" | "OffTrack";
}

export class Filter extends Component<Properties, FilterState> {

    constructor(props) {
        super(props);

        this.state = {
            text: "",
            campusId: 0,
            programId: 0,
            onTrack: "All"
        };

        this.onKeyPress = this.onKeyPress.bind(this);
        this.onCampusChange = this.onCampusChange.bind(this);
        this.onProgramChange = this.onProgramChange.bind(this);
        this.onGate = this.onGate.bind(this);
    }

    public async componentDidMount() {
        this.props.gate.addListener(this.onGate);
    }

    public async componentWillUnmount() {
        this.props.gate.removeListener(this.onGate);
    }

    private onGate(data) {
        if (data.majors) {
            this.setState({programId: 0});
        }
    }

    private onChange(e: SyntheticEvent<any>) {
        let target = e.target as HTMLInputElement;
        const {name, value} = target;
        //console.log(`${name} - ${value}`);
        let entry = {};
        entry[target.name] = target.value;
        this.setState(entry);
        /*let filterParams: FilterParameters = {
            selectedClient: this.state.selectedClient,
            dateFrom: this.state.dateFrom,
            dateTo: this.state.dateTo
        };*/


        /*let filterParams: FilterParameters = {
            ...this.state
        };*/

        //filterParams[target.name] = target.value;

        // this.props.onChange(filterParams);

    }

    private onCampusChange(event) {
        let campusId = parseInt(event.target.value);
        this.setState({campusId: campusId});
        if (this.props.onCampusChanged) {
            this.props.onCampusChanged(campusId);
        }
    }

    private onTrackChanged(event) {
        let onTrack = event.target.value;
        this.setState({onTrack: onTrack});
    }

    private onProgramChange(event) {
        this.setState({programId: parseInt(event.target.value)});
    }

    private onKeyPress(e) {
        if (e.key === "Enter") {
            this.onClick();
        }
    }

    private onClick() {
        this.props.onChange({...this.state});
    }

    public render() {
        let options = this.props.campuses.map((item: any, index) => {
            return <option key={index} value={item.value}>{item.name}</option>;
        });

        let programOptions = this.props.programs.map((item: any, index) => {
            return <option key={index} value={item.value}>{item.name}</option>;
        });
        return (
            <div>
                <hr/>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="">Campus:</label>
                        <select name=""
                                className="form-control"
                                disabled={this.props.disabled}
                                onChange={this.onCampusChange}
                        >
                            {options}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="">Program:</label>
                        <select name=""
                                value={this.state.programId}
                                id="major-dropdown"
                                className="form-control"
                                disabled={this.props.disabled}
                                onChange={this.onProgramChange}
                        >
                            {programOptions}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label>Name or Email</label>
                        <input type="text" name="text"
                               className="form-control"
                               onChange={this.onChange.bind(this)}
                               onKeyPress={this.onKeyPress}
                               disabled={this.props.disabled}
                               value={this.state.text}
                        />
                    </div>
                    <div className="col-md-1 d-flex flex-column">
                        <button type="button"
                                onClick={this.onClick.bind(this)}
                                className={classNames(
                                    "btn btn-primary form-control mt-auto button",
                                    {disabled: this.props.disabled}
                                )}>
                            Filter
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="">On Track:</label>
                        <select name=""
                                className="form-control"
                                disabled={this.props.disabled}
                                onChange={this.onTrackChanged.bind(this)}
                        >
                            <option key="1" value="All">All</option>
                            <option key="2" value="OnTrack">On Track</option>
                            <option key="3" value="OffTrack">Off Track</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}